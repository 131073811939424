// import { SharedButton } from '@rtorcato/tripstreamer-shared-components';
import React, { memo } from 'react';

import ComingSoonImage from './comingsoon.png';

// <SharedButton>Shared button woot!!! 😀 😎 👍 💯</SharedButton>

const ComingSoon = () => (
  <React.Fragment>
    <div className="main-container d-flex flex-column">
      <section className="mb-auto">
        <div className="container">
          <div className="row">
            <div className="col text-center">www.Tripstreamer.com</div>
          </div>
        </div>
      </section>
      <section className="">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-12 col-md-6 mb-4">
              <img alt="ImageGraphic" src={ComingSoonImage} className="w-100" />
            </div>

            <div className="col-12 col-md-7 col-lg-5 mb-4 text-center text-md-left">
              <h1 className="display-3">
                Our new app
                <br />
                is launching soon!
              </h1>
              <h2 className="lead">Forget everything you know about the old way, the new way is coming.</h2>
              <div>
                <a href="mailto:rt@matrixdigital.com" className="btn btn-link">
                  Request beta access
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mt-auto">
        <div className="container">
          <div className="row">
            <div className="col text-center">
              <span className="text-muted text-small">
                Made with{' '}
                <span role="img" aria-label="coffee">
                  ☕
                </span>{' '}
                by <a href="https://www.matrixdigital.com">Matrix Digital Solutions Inc.</a>
              </span>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div className="container" style={{ display: 'none' }}>
      <div className="row1">
        <ins style={{ display: 'inline-block', height: '174px', width: '100%', backgroundColor: '#CCC' }} className="adsbygoogle" data-adtest1="on" data-ad-format="fluid" data-ad-layout-key="-fb+5w+4e-db+86" data-ad-client="ca-pub-7493974733215010" data-ad-slot="3949528736">
          Google Ad
        </ins>
      </div>
    </div>
  </React.Fragment>
);

export default memo(ComingSoon);
