import './styles.scss';

import React, { Component } from 'react';

import ComingSoon from '../comingsoon';

class App extends Component {
  public render() {
    return <ComingSoon />;
  }
}

export default App;
